<template>
  <div class="d-flex justify-center" style="width: 100%">
    <v-container fluid style="max-width: 1030px" v-if="brands !== ''">
      <h1 class="text-center mb-8">{{ this.brands.name }}</h1>
      <v-row>
        <v-col
          v-for="(item, i) in brands.children"
          :key="i"
          :md="show.cols.value"
        >
          <div
            style="text-align: center"
            class="brand-link"
            :style="{ height: show.x }"
          >
            <router-link :to="{ path: '/kategoria/' + getSeoUrl(item) }">
              <v-card flat>
                <!-- <v-icon :size="show.size"> ${{ item }} </v-icon> -->
                <v-img
                  height="150"
                  width="150"
                  class="mx-auto"
                  v-if="item.thumbnail"
                  :src="imgurl + item.thumbnail + '/small.png'"
                ></v-img>
                <v-img v-else src="@/assets/images/no-img.jpg"></v-img>

                <!-- <v-card-title> {{ item.name }}</v-card-title> -->
              </v-card>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      brands: "",
      show: this.$store.state.cms.data.brands,
      url: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/tree",
      url2: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/category",
      url3: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/get/category/",
      imgurl: process.env.VUE_APP_BBSHOP_IMAGES_ENDPOINT,
    };
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: "description",
          property: "description",
          content: "Loviisan Aitta Brändit",
        },
      ],
    };
  },
  methods: {
    getSeoUrl: function(item) {
      for (var i in this.$store.state.cms.categoryData) {
        if (this.$store.state.cms.categoryData[i].name.fi == item.name) {
          return this.$store.state.cms.categoryData[i].seoName;
        }
      }
    },
    brandLink(url) {
      let uuid = this.categoryUUID(url);
      this.$router.push({ path: "/product-list/" + uuid.name.fi });
      // console.log(uuid.uuid);
    },
    async getBrands() {
      // let data = {
      //   from: 0,
      //   size: 100,
      //   query: {
      //     match_phrase: {
      //       name: this.$store.state.cms.data.brands.uuid,
      //     },
      //   },
      // };

      let data = {
        from: 0,
        size: 100,
        query: {
          bool: {
            must: {
              match_phrase: {
                "tree.children.name": this.$store.state.cms.data.brands.uuid,
              },
            },
          },
        },
        _source: ["tree.children"],
      };

      await this.axios.post(this.url, data).then((res) => {
        res.data.items[0].tree.children.map((e) => {
          if (e.name === this.$store.state.cms.data.brands.uuid) {
            this.brands = e;
          }
        });
      });
      // console.log(this.brands.children);
      await this.brands.children.map((e, i) => {
        let data2 = {
          from: 0,
          size: 1,
          query: {
            match_phrase: {
              uuid: e.data,
            },
          },
        };
        this.axios.post(this.url2, data2).then((res) => {
          // console.log(e.name + JSON.stringify(res.data.items[0]))
          // console.log(res.data)
          if (res.data.items[0] !== undefined) {
            if (res.data.items[0].thumbnail) {
              this.$set(
                this.brands.children[i],
                "thumbnail",
                res.data.items[0].thumbnail
              );
            }
          }
        });
      });
    },
  },
  computed: {
    ...mapGetters(["categoryUUID"]),
  },
  mounted() {
    if (this.$store.state.cms.data.brands) {
      this.getBrands();
    }
  },
  watch: {
    "$store.state.cms.data.brands": function() {
      this.getBrands();
    },
  },
};
</script>

<style scoped>
.brand-link {
  border: none;
}

.v-card {
  border: 1px solid #fff;
  transition: transform 0.2s;
}

.v-card:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.v-card__title {
  font-family: "Lora", sans-serif;
  justify-content: center;
}
</style>
